
html, body, :global(#root) {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: scroll;
}

.react-calendar{
 margin: auto;
}
